export const CURRENT_LIST_KEY = 'currentSlots';
export const FINISHED_LIST_KEY = 'finishedSlots';
export const STATUS_LOADING_KEY = (reservationId: any) => `${reservationId}-statusLoading`;

export const ALL_DAY = 'All day';
export const ALL_DAY_AVAILABILITY = -1;

export const STATUSES = {
  RESERVED: 'RESERVED',
  CONFIRMED: 'CONFIRMED',
  LATE: 'LATE',
  LEFT_MESSAGE: 'LEFT_MESSAGE',
  SENT_MESSAGE: 'MSG_SENT',
  ARRIVED: 'ARRIVED',
  PARTIALLY_SEATED: 'PARTIALLY_SEATED',
  SEATED: 'SEATED',
  STARTER: 'STARTER',
  MAIN: 'MAIN',
  DESSERT: 'DESSERT',
  CLEARED: 'CLEARED',
  FINISH: 'FINISH',
  PAID: 'PAID',
  FAILED: 'FAILED',
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  BILL_DROPPED: 'BILL_DROPPED',
  CANCELLED: 'CANCELLED',
  EXPIRED: 'EXPIRED',
  'NO-SHOW': 'NO-SHOW',
  REJECTED: 'REJECTED',
};

// NOTE: this is for table blocked adding here because we are using workaround in timeline view to show blocked table like reservation
export const BLOCKED_STATUS = 'BLOCKED';
// NOTE: this is status for all attention statuses used in the quick filter
export const ATTENTION_STATUS = 'ATTENTION';

export const WAITLIST_STATUSES = {
  NOTIFY: 'NOTIFY',
  EXPIRED_NOTIFY: 'EXPIRED_NOTIFY',
  CANCELLED_NOTIFY: 'CANCELLED_NOTIFY',
};

export const QUEUE_LIST_STATUSES = {
  REMOVED: 'REMOVED',
  ACTIVE: 'ACTIVE',
  SEATED: 'QUEUE_SEATED',
  SEND_SMS: 'SEND_SMS',
  REMOVE_FROM_QUEUE: 'REMOVE_FROM_QUEUE',
  REJECTED_QUEUE: 'REJECTED_QUEUE',
  CANCELLED_QUEUE: 'CANCELLED_QUEUE',
  QUEUE_TURN_CONFIRMATION_SMS_SENT: 'QUEUE_TURN_CONFIRMATION_SMS_SENT',
  TABLE_READY_SMS_SENT: 'TABLE_READY_SMS_SENT',
  CUSTOM_TEMPLATE_SMS_SENT: 'CUSTOM_TEMPLATE_SMS_SENT',
  HEAD_TO_VENUE_SMS_SENT: 'HEAD_TO_VENUE_SMS_SENT',
};

export const ACTIVE_QUEUE_STATUSES = [
  QUEUE_LIST_STATUSES.ACTIVE,
  QUEUE_LIST_STATUSES.SEND_SMS,
  QUEUE_LIST_STATUSES.QUEUE_TURN_CONFIRMATION_SMS_SENT,
  QUEUE_LIST_STATUSES.TABLE_READY_SMS_SENT,
  QUEUE_LIST_STATUSES.CUSTOM_TEMPLATE_SMS_SENT,
  QUEUE_LIST_STATUSES.HEAD_TO_VENUE_SMS_SENT,
];

export const RESERVED_SUBSTATUSES = [
  STATUSES.CONFIRMED,
  STATUSES.LATE,
  STATUSES.LEFT_MESSAGE,
  STATUSES['NO-SHOW'],
  STATUSES.PARTIALLY_SEATED,
  STATUSES.ARRIVED,
];

export const SEATED_SUBSTATUSES = [
  STATUSES.STARTER,
  STATUSES.MAIN,
  STATUSES.DESSERT,
  STATUSES.CLEARED,
  STATUSES.BILL_DROPPED,
  STATUSES.PAID,
];

export const PRE_SEATED_STATUSES = [
  STATUSES.ARRIVED,
  STATUSES.AWAITING_PAYMENT,
  STATUSES.CONFIRMED,
  STATUSES.LATE,
  STATUSES.LEFT_MESSAGE,
  STATUSES.PARTIALLY_SEATED,
  STATUSES.RESERVED,
  STATUSES.SENT_MESSAGE,
];

export const SEATED_STATUSES = [STATUSES.SEATED, ...SEATED_SUBSTATUSES];

export const INACTIVE_STATUSES = [
  STATUSES.CANCELLED,
  STATUSES['NO-SHOW'],
  STATUSES.FAILED,
  STATUSES.EXPIRED,
  STATUSES.REJECTED,
];

export const FINISHED_STATUSES = [STATUSES.FINISH, ...INACTIVE_STATUSES];

export const UNFINISHED_STATUSES = Object.values(STATUSES).filter(
  (status) => !FINISHED_STATUSES.includes(status)
);

export const RESERVATION_LIST_TYPES = {
  WAITLIST: 'WAITLIST',
  FINISHED: 'FINISHED',
  RESERVED: 'RESERVED',
  QUEUE: 'QUEUE',
} as const;

export const SOURCE_OF_RESERVATION = {
  INHOUSE: 'in_house',
  WIDGET: 'widget',
  WALKIN: 'walk_in',
  UMAI: 'umai',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  GOOGLE: 'reserve_with_google',
  IMPORT: 'import',
  FEATURED: 'featured',
  BOOKING_PLATFORM: 'booking_platform',
} as const;

// In minutes
export const GRACE_TIME_FOR_RESERVATION_STARTTIME = 29;
export const GRACE_TIME_FOR_RESERVATION_ENDTIME = 29;
export const GRACE_TIME_FOR_RESERVATION_LATE = 5;

export const DEFAULT_TURN_TIME = 120;

export const MAX_PARTY_SIZE = 9999;
export const MIN_PARTY_SIZE = 1;

export const EXPIRATION_VIEW_TYPES = {
  LIST: 'list',
  BASIC: 'basic',
  WARNING: 'warning',
};

export const WAITLIST_TIME_INTERVAL = 15;

export const TAG_TYPE = {
  RESERVATION: 'reservation_tag',
  GUEST: 'guest_tag',
  RESERVATION_AND_GUEST: 'reservation_and_guest_tag',
};

export const TAG_TYPE_RESERVATION = [TAG_TYPE.RESERVATION, TAG_TYPE.RESERVATION_AND_GUEST];

export const TAG_TYPE_GUEST = [TAG_TYPE.GUEST, TAG_TYPE.RESERVATION_AND_GUEST];

export const TAG_CATEGORY_ID = {
  SPECIAL_TAGS: '1',
  DIETARY_TAGS: '2',
};

export const RESERVATION_DETAILS_VIEW = {
  RESERVATION_VIEW: 'ReservationDetails',
  GUEST_VIEW: 'GuestView',
};

export const FORM_DEBOUNCE_TIME = 3000;

export const MAX_TABLE_LENGTH = 8;

export const MAX_RESERVATION_NOTE_LENGTH_DESKTOP = 80;

export const MAX_RESERVATION_NOTE_LENGTH = 30;

export const MAX_GUEST_NOTE_LENGTH_DESKTOP = 80;

export const MAX_GUEST_NOTE_LENGTH = 30;

export const MAX_RESERVATION_TAGS_DESKTOP = 5;

export const MAX_RESERVATION_TAGS = 2;

export const MAX_GUEST_TAGS_DESKTOP = 5;

export const MAX_GUEST_TAGS = 2;

export const TWO_ZEROS_PHONE_NUMBER = '00';

export const RESERVATION_ACTIVITY_TYPE = {
  statusChange: 'status_change',
  updated: 'updated',
  created: 'created',
  loyaltyCollection: 'loyalty_collection',
  loyaltyRedemption: 'loyalty_redemption',
  loyaltyDeduction: 'loyalty_deduction',
  loyaltyAddition: 'loyalty_addition',
  feedbackReceived: 'feedback_received',
  cancellationFee: 'cancellation_fee',
  deposit: 'deposit',
  refund: 'refund',
  deTabling: 'de_tabling',
  customChargeCreated: 'custom_charge_created',
  customChargeUpdated: 'custom_charge_updated',
  customChargeDeleted: 'custom_charge_deleted',
} as const;

export const COMMUNICATION_ACTIVITIES = {
  edited_message: 'Edit message',
  canceled_message: 'Cancel message',
  confirmation_message: 'Confirm message',
  reminder_1_message: 'Reminder 1 message',
  reminder_2_message: 'Reminder 2 message',
  reminder_3_message: 'Reminder 3 message',
  feedback_request_message: 'Feedback request message',
  payment_message: 'Payment message',
  payment_link_expired_message: 'Payment link expired message',
  waitlist_confirmation_message: 'Queue confirm message',
  table_confirmation_message: 'Table confirm message',
  head_to_venue_message: 'Head to venue message',
  redemption_otp_message: 'Redemption OTP message',
  redemption_message: 'Redemption message',
  collection_message: 'Collection message',
  deduction_message: 'Deduction message',
  addition_message: 'Addition_message',
  self_signup_message: 'Self signup message',
  profile_link_message: 'Profile link message',
  reward_self_signup_cashback_message: 'Reward self signup cashback message',
  reward_self_signup_custom_message: 'Reward self signup custom message',
  notifylist_confirmation_message: 'Queue confirm message',
  notifylist_cancellation_message: 'Queue cancel message',
} as const;

export const RESERVATION_SPEND = {
  optional: 'optional',
  mandatory: 'mandatory',
  noneSpend: 'none_spend',
} as const;

export const SEARCH_PLACEHOLDER_TEXT = {
  reservation: 'Search Reservation',
  queue: 'Search Queue',
  waitlist: 'Search Waitlist',
};

// Reservations made not from webrms but from widget including google and meta reserve
export const WIDGET_RESERVATION_SOURCES = [
  SOURCE_OF_RESERVATION.WIDGET,
  SOURCE_OF_RESERVATION.FACEBOOK,
  SOURCE_OF_RESERVATION.INSTAGRAM,
  SOURCE_OF_RESERVATION.GOOGLE,
  SOURCE_OF_RESERVATION.BOOKING_PLATFORM,
] as const;

export const RESERVATION_WARNINGS = {
  LATE_RESERVATION: `It is ${GRACE_TIME_FOR_RESERVATION_LATE} minutes past the reservation start time`,
  SAME_TABLE_STATUS: 'This reservation has had the same table status for a long time',
  LOCKED_TABLE: 'This reservation has a locked table',
  DOUBLE_BOOKING_SAME_RESTAURANT: 'This guest has double booked in your restaurant',
  DOUBLE_BOOKING_OTHER_RESTAURANT: 'This guest has a double booking with another restaurant',
} as const;

export const ACTIVITY_TRACKABLE_TYPES = {
  RESERVATION: 'Reservation',
  OFFLINEWAITLIST: 'OfflineWaitlist',
};
