export const RESERVATION_FUNNEL_STEPS = {
  FIRST_STEP: 'FIRST_STEP',
  SELECT_TIME_SLOT: 'SELECT_TIME_SLOT',
  SELECT_TABLES: 'SELECT_TABLES',
  SELECT_GUEST: 'SELECT_GUEST',
  CREATE_GUEST: 'CREATE_GUEST',
  CONFIRM_RESERVATION: 'CONFIRM_RESERVATION',
  SELECT_QUEUE_PARTY_AND_GUEST: 'SELECT_QUEUE_PARTY_AND_GUEST',
  CREATE_QUEUE_GUEST: 'CREATE_QUEUE_GUEST',
} as const;

export const COMMUNICATION_LABEL = {
  sms: 'SMS',
  smsWA: 'SMS/WA',
  email: 'Email',
  viaSms: 'Via SMS',
  viaEmail: 'Via Email',
};
// prettier-ignore
export const PARTY_SIZES_MOBILE = [
  1, 2, 3, 4,
  5, 6, 7, 8,
  9, 10, 11, 12,
];

export const PARTY_SIZES_TIMELINE = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const PARTY_SIZES_TABLET = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

export const SEATED_TABLE_INTERVAL = 60000;

export const TURN_TIME_TYPE = {
  REGULAR: 'Regular',
  SHORT: 'Short',
  SQUEEZE: 'Squeeze',
} as const;

export const MANUAL_PAYMENT_CHARGE_TYPES = {
  NO_PAYMENT: { label: 'No payment', value: 'no-payment' },
  DEFAULT_PAYMENT: { label: 'Default payment', value: 'default-payment' },
  DEPOSIT: { label: 'Deposit', value: 'deposit' },
  PREPAYMENT: { label: 'Prepayment', value: 'prepayment' },
  CANCELLATION_FEE: { label: 'Cancellation fee', value: 'cancellation' },
} as const;

export const MANUAL_PAYMENT_CHARGE_MODAL_OPTIONS = [
  MANUAL_PAYMENT_CHARGE_TYPES.PREPAYMENT,
  MANUAL_PAYMENT_CHARGE_TYPES.DEPOSIT,
  MANUAL_PAYMENT_CHARGE_TYPES.CANCELLATION_FEE,
] as const;

export const MANUAL_PAYMENT_OPTIONS = Object.values(MANUAL_PAYMENT_CHARGE_TYPES);

export const UNLOCK_TABLE_ID = 0;

export const DEFAULT_GUEST_COUNT = 2;

// days and availability note textarea height
export const MIN_TEXTAREA_HEIGHT = 32;

export const THIRD_PARTY_BOOKER_STEPS = {
  GUEST_SELECTION_STEP: 'GUEST_SELECTION_STEP',
  CREATE_THIRD_PARTY_BOOKER: 'CREATE_THIRD_PARTY_BOOKER',
};
